import newPartnerReducer from './newPartnerReducer';
import microLoansReducer from './microLoansReducer';
import allServicesReducer from './allServicesReducer';
import partnerListReducer from './partnerListReducer';
import currencyListReducer from './currencyListReducer';
import updatePartnerReducer from './updatePartnerReducer';
import setBaseCurrenciesReducer from './setBaseCurrenciesReducer';
import getGlobalFinancesReducer from './getGlobalFinancesReducer';
import updateOssixBalanceReducer from './updateOssixBalanceReducer';
import updatePartnerBalanceReducer from './updatePartnerBalanceReducer';
import setCrowdfundingRateFeeReducer from './setCrowdfundingRateFeeReducer';
import getCrowdfundingRateFeeReducer from './getCrowdfundingRateFeeReducer';
import activateOrDeletePartnerReducer from './activateOrDeletePartnerReducer';
import registerAsTwoWayPartnerReducer from './registerAsTwoWayPartnerReducer';
import FinancesInitialStateInterface from 'types/finances/financialInitialState';
import updateParamsFinanceConfigReducer from './updateParamsFinanceConfigReducer';
import updateServicesTransferFeesReducer from './updateServicesTransferFeesReducer';
import getBankBranchesListReducer from './getBankBranchesList';

const financesReducer = (state: FinancesInitialStateInterface, action: any) => {
  return {
    ...state,
    ...currencyListReducer(state, action),
    ...setBaseCurrenciesReducer(state, action),
    ...setCrowdfundingRateFeeReducer(state, action),
    ...getCrowdfundingRateFeeReducer(state, action),
    ...getGlobalFinancesReducer(state, action),
    ...updateParamsFinanceConfigReducer(state, action),
    ...allServicesReducer(state, action),
    ...updateServicesTransferFeesReducer(state, action),
    ...partnerListReducer(state, action),
    ...updatePartnerReducer(state, action),
    ...registerAsTwoWayPartnerReducer(state, action),
    ...newPartnerReducer(state, action),
    ...activateOrDeletePartnerReducer(state, action),
    ...updatePartnerBalanceReducer(state, action),
    ...updateOssixBalanceReducer(state, action),
    ...microLoansReducer(state, action),
    ...getBankBranchesListReducer(state, action),
  };
};

export default financesReducer;

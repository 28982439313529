import {
  ADD_UPDATE_MERCHANT_START,
  ADD_UPDATE_MERCHANT_SUCCESS,
  ADD_UPDATE_MERCHANT_FAILURE,
} from "redux/actionTypes/merchant";

const addUpdateMerchant = (state: any, { type, payload }: any) => {
  switch (type) {
    case ADD_UPDATE_MERCHANT_START:
      return {
        ...state,
        addUpdateMerchant: { loading: true, data: null, error: null },
      };

    case ADD_UPDATE_MERCHANT_SUCCESS: {
      const newMerchantData = Object.assign({}, payload);
      let listData = Array.from(state.merchantList?.data?.[0].Data);
      if (listData) {
        const oldOpIndex =
          listData &&
          listData?.findIndex(
            ({ OrganizationID }: any) =>
              OrganizationID === payload.OrganizationID
          );
        if (oldOpIndex !== -1) {
          listData.splice(oldOpIndex, 1, newMerchantData);
        } else {
          listData.unshift(newMerchantData);
        }
      } else {
        listData = [];
      }

      const merchantListData = state.merchantList.data[0];
      return {
        ...state,
        merchantList: {
          ...state.merchantList,
          data: [{ ...merchantListData, Data: listData }],
        },
        addUpdateMerchant: { loading: false, data: payload, error: null },
      };
    }

    case ADD_UPDATE_MERCHANT_FAILURE:
      return {
        ...state,
        merchantList: { ...state.merchantList },
        addUpdateMerchant: { loading: false, data: null, error: null },
      };

    default:
      return null;
  }
};

export default addUpdateMerchant;

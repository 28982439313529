import {
  Row,
  Col,
  Spin,
  Space,
  Empty,
  Typography,
  PaginationProps,
} from "antd";
import moment from "moment";
import { useIntl } from "react-intl";
import Table from "components/Table";
import { PropsWithChildren, useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { LoadingOutlined } from "@ant-design/icons";
import TransactionsDetails from "./TransactionDetails";
import TransactionInterface from "types/finances/Transaction";
import { TransactionsFilters } from "./utils";

const { Text } = Typography;

interface Props {
  loading: boolean;
  filters?: TransactionsFilters;
  size?: "default" | "small";
  pagination?: PaginationProps;
  transactions: TransactionInterface[];
}

const TransactionsTable = ({
  loading,
  filters,
  pagination,
  transactions,
  children,
  size = "default",
}: PropsWithChildren<Props>) => {
  const intl = useIntl();
  const [selectedTransaction, setSelectedTransaction] =
    useState<TransactionInterface>();
  const [isCardVisible, setCardVisible] = useState(false);

  const toggleCard = () => {
    setCardVisible(!isCardVisible);
  };

  const showCard = () => {
    setCardVisible(true);
  };

  useEffect(() => {
    if (transactions?.length) {
      setSelectedTransaction(transactions[0]);
    }

    return () => {};
  }, [transactions]);

  const tableColumns: ColumnsType<TransactionInterface> = [
    {
      title: "Transaction",
      render: (_, record) => {
        return (
          <Space direction="vertical" size={0}>
            <Text type="secondary" className="text-small">
              {"#"}
              {record.TransactionNumber}&nbsp;&nbsp;{record.WalletNumber}
            </Text>
            <Text>
              {record.ContactFirstName}&nbsp;{record.ContactLastName}
            </Text>
          </Space>
        );
      },
    },
    {
      dataIndex: "Date",
      title: intl.formatMessage({ id: "1258", defaultMessage: "Date & time" }),
      render: (date: string) => {
        const formattedDate = moment(date.replace(/\s-\s/, " "));
        return (
          <Text>
            {formattedDate.format(
              size === "small" ? "DD MMM YYYY H:mm" : "DD MMM YYYY, HH:mm A"
            )}
          </Text>
        );
      },
    },
    {
      title: intl.formatMessage({
        id: "dd.new",
        defaultMessage: "Reference",
      }),
      dataIndex: "Reference",
      render: (_, record) => (
        <Space direction="vertical">
          <Text ellipsis>{record.Reference || record.Description}</Text>
        </Space>
      ),
    },

    {
      align: "left",
      title: intl.formatMessage({ id: "116", defaultMessage: "Amount" }),
      render: (_, record) =>
        filters?.PID || filters?.WalletNumber ? (
          <Text type={record.OpsType === "+" ? "success" : "danger"} strong>
            {record.Currency}&nbsp;{record.Amount}
          </Text>
        ) : (
          <Text strong>
            {record.Currency}&nbsp;{record.Amount}
          </Text>
        ),
    },
    {
      align: "left",
      title: intl.formatMessage({
        id: "116",
        defaultMessage: "Previous balance",
      }),
      render: (_, record) =>
        filters?.PID || filters?.WalletNumber ? (
          <Text type={record.OpsType === "+" ? "success" : "danger"} strong>
            {!record.PreviousBalance || record.PreviousBalance === "0"
              ? ""
              : `${record.Currency} ${record.PreviousBalance}`}
          </Text>
        ) : (
          <Text strong>
            {!record.PreviousBalance || record.PreviousBalance === "0"
              ? ""
              : `${record.Currency} ${record.PreviousBalance}`}
          </Text>
        ),
    },

    {
      align: "left",
      title: intl.formatMessage({
        id: "116",
        defaultMessage: "New balance",
      }),
      render: (_, record) =>
        filters?.PID || filters?.WalletNumber ? (
          <Text type={record.OpsType === "+" ? "success" : "danger"} strong>
            {!record.NewBalance || record.NewBalance === "0"
              ? ""
              : `${record.Currency} ${record.NewBalance}`}
          </Text>
        ) : (
          <Text strong>
            {!record.NewBalance || record.NewBalance === "0"
              ? ""
              : `${record.Currency} ${record.NewBalance}`}
          </Text>
        ),
    },
  ];

  return (
    <Row gutter={[24, 0]}>
      <Col span={isCardVisible ? 18 : 24}>
        <Table
          size="middle"
          loading={{
            spinning: loading,
            indicator: <Spin indicator={<LoadingOutlined spin />} />,
          }}
          locale={{
            emptyText: (
              <Empty
                style={{ marginTop: 96, marginBottom: 96 }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={intl.formatMessage({
                  id: "dd.new",
                  defaultMessage: "No transactions found",
                })}
              />
            ),
          }}
          columns={tableColumns}
          dataSource={transactions?.slice(0, Number(pagination?.pageSize))}
          rowClassName={(r) =>
            r.TransactionNumber === selectedTransaction?.TransactionNumber
              ? "btn-cursor-pointer ant-table-cell-row-hover"
              : "btn-cursor-pointer"
          }
          showHeader={!!transactions.length}
          pagination={{ hideOnSinglePage: true, ...pagination }}
          rowKey={(record) => {
            return `#${record.OpsType}${record.TransactionNumber}/${
              record.WalletNumber
            }/${record.Date.replaceAll(" ", "")}`;
          }}
          onRow={(record) => ({
            onClick: () => {
              showCard();
              setSelectedTransaction(record);
            },
          })}
        />
      </Col>

      <Col span={6}>
        <Row gutter={[0, 24]}>
          {children ? <Col span={24}>{children}</Col> : null}

          <Col span={24}>
            <TransactionsDetails
              isLoading={loading}
              transaction={selectedTransaction}
              handleButtonClick={toggleCard}
              isCardVisible={isCardVisible}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TransactionsTable;

import axios from "utils/axios";
import { AxiosResponse } from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import TransactionInterface from "types/finances/Transaction";

interface FetchTransactionsRequestBody {
  DateTo?: string;
  DateFrom?: string;
  PID?: string;
  PageNumber?: string;
  Proxy?: "YES" | "NO";
  WalletNumber?: string;
  RecordPerPage?: string;
  CountryCode?: string;
}



interface FetchTransactionsResponse {
  Data: TransactionInterface[];
  Meta: {
    TotalPages: string;
    CurrentPage: string;
    TotalRecords: string;
    RecordsperPage: string;
    CreditCount: string;
    DebitCount: string;
    TotalCredit: string;
    TotalDebit: string;
    InternalCount: string;
    TotalInternal: string;
    Currencies: any;
  };
}

const fetchTransactionsQueryFn = (
  requestBody: FetchTransactionsRequestBody
) => {
  const {
    REACT_APP_LOGIN_NAME: LoginName,
    REACT_APP_API_KEY: APIKey,
    REACT_APP_ID: AppID,
  } = process.env;

  return axios
    .post<any, AxiosResponse<FetchTransactionsResponse[]>>("GetTransactions", {
      AppID,
      APIKey,
      LoginName,
      ...requestBody,
    })
    .then((response) => {
      // @ts-expect-error
      if (response.data[0].Error) {
        return {
          Data: [],
          Meta: {
            TotalPages: "0",
            CurrentPage: "1",
            TotalRecords: "0",
            RecordsperPage: requestBody.RecordPerPage || "0",
            CreditCount: "0",
            DebitCount: "0",
            TotalCredit: "",
            TotalDebit: "",
            InternalCount: "",
            TotalInternal: "",
            Currencies: []
          },
        };
      } else return response.data[0];
    });
};

export const useDownloadTransactionsMutation = () =>
  useMutation({ mutationFn: fetchTransactionsQueryFn });

export const useFetchTransactions = (
  requestBody: FetchTransactionsRequestBody
) => {
  return useQuery({
    queryKey: ["GetTransactions", ...Object.values(requestBody)],
    staleTime: 1000 * 60 * 2.5,
    refetchOnMount: false,
    enabled: Boolean(
      (
        requestBody.WalletNumber ||
        requestBody.PID ||
        requestBody.DateFrom ||
        requestBody.DateTo
      )?.length && requestBody?.CountryCode
    ),
    queryFn: () => fetchTransactionsQueryFn(requestBody),
  });
};

import {
  Col,
  Row,
  Spin,
  Input,
  Space,
  Badge,
  Button,
  Typography,
  DatePicker,
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import PageHOCComponent from "components/PageHOC";
import InitialStateInterface from "types/initialState";
import FormattedNumber from "components/FormattedNumber";
import BackgroundVector1 from "assets/images/bg-vector-1.svg";
import BackgroundVector2 from "assets/images/bg-vector-2.svg";
import { getDashboardKPIDataAction, getDashboardKPIGraphDataAction } from "redux/actions/dashboard";
import SummaryReportImage from "assets/images/report-flyer-cover.png";
import { ReactComponent as MoneyIconSVG } from "assets/icons/money.svg";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ReactComponent as ContinueIconSvg } from "assets/icons/continue.svg";
import { ReactComponent as InvestmentIconSVG } from "assets/icons/return-investment.svg";
import { PermissionNames } from "constants/enumerators";
import PageAccessControl from "components/PageAccessControl";
import { useFetchTransactions } from "queries/useFetchTransactions";
import { TransactionsFiltersModal } from "components/TransactionsFiltersModal";
import { TransactionsPartnersFiltersModal } from "components/TransactionsPartnersFiltersModal";
import { TransactionsFilters } from "components/TransactionsTable/utils";
import Icon, {
  LoadingOutlined,
  FileExcelOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import DownloadConfigModal from "components/TransactionsTable/DownloadConfigModal";
import TransactionsTable from "components/TransactionsTable";
import "./index.scss";
import ExportToXLSX from "components/TransactionsTable/ExportToXLSX";

interface ICurrencies {
  Code: string;
  Name: string;
  Flag: string;
  CreditCount: string;
  DebitCount: string;
  InternalCount: string;
  TotalCredit: string;
  TotalDebit: string;
  TotalInternal: string;
}

const { Text, Title } = Typography;
const { RangePicker } = DatePicker;
const typedUseSelector: TypedUseSelectorHook<InitialStateInterface> =
  useSelector;

const TransactionReport = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isFiltersModalVisible, setFiltersModalVisible] = useState(false);
  const [isPartnersFiltersModalVisible, setPartnersFiltersModalVisible] =
    useState(false);
  const [downloadConfigVisible, setDownloadConfigVisible] = useState(false);
  const [openExportToXLSX, setOpenExportToXLSX] = useState(false);
  const [walletNumber, setWalletNumber] = useState("");
  const [PID, setPID] = useState("");

  const [dateRange, setDateRange] = useState([
    moment().subtract(1, "month"),
    moment(),
  ]);

  useEffect(() => {
    setFilters((prev) => ({ ...prev, PID, WalletNumber: walletNumber }));
  }, [walletNumber, PID]);

  const {
    dashboard: {
      KPI: {
        loading: isLoadingKPI,
        data: { TransVolume },
      },
    },
    globalSettings: { globalBuiltinConfigData },
    currentUser: { selectedOpsCountry },
  } = typedUseSelector(({ dashboard, users, globalSettings }) => ({
    dashboard,
    currentUser: users.currentUser,
    globalSettings,
  }));
  const [selectOptionForm, setSelectOptionForm] = useState({
    DateTo: moment().format("YYYY-MM-DD"),
    DateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
    RecordPerPage: "10",
  });


  const [filters, setFilters] = useState<TransactionsFilters>({
    PID,
    Proxy: "YES",
    PageNumber: "1",
    RecordPerPage: "10",
    TransactionType: [],
    Membership: [],
    WalletNumber: walletNumber,
    DateTo: moment().format("YYYY-MM-DD"),
    DateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
    CountryCode: globalBuiltinConfigData?.data?.BaseCountry?.BaseCountryCode,
  });
  const { isFetching, data } = useFetchTransactions(filters);

  const currenciesData = data?.Meta?.Currencies?.filter(
    (currencyData: ICurrencies) =>
      currencyData.Code === TransVolume?.BaseCurrency
  )[0];

  useEffect(() => {
    if (selectedOpsCountry) {
      getDashboardKPIDataAction(selectedOpsCountry)(dispatch);
    }
  }, [dispatch, selectedOpsCountry]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      RecordPerPage: selectOptionForm.RecordPerPage,
      DateTo: selectOptionForm.DateTo,
      DateFrom: selectOptionForm.DateFrom,
    }));
  }, [selectOptionForm]);

  useEffect(() => {
    if (globalBuiltinConfigData) {
      setFilters((prev) => ({
        ...prev,
        CountryCode:
          globalBuiltinConfigData?.data?.BaseCountry?.BaseCountryCode,
      }));
    }
  }, [globalBuiltinConfigData]);

  return (
    <PageHOCComponent
      pageClassName="transactions-report__wrapper"
      title={intl.formatMessage({
        id: "2567",
        defaultMessage: "Transactions report",
      })}
      displayTitle={intl.formatMessage({
        id: "2567",
        defaultMessage: "Transactions report",
      })}
    >
      <PageAccessControl permID={PermissionNames.REPORTING}>
        <Spin
          spinning={isLoadingKPI}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          tip={`${intl.formatMessage({
            id: "194",
            defaultMessage: "Loading",
          })}...`}
        >
          <Row className="transactions-report" gutter={[0, 56]}>
            <Col span={24}>
              <Row style={{ marginTop: 16 }}>
                <Col
                  span={10}
                  className="transactions-report__card"
                  style={{ backgroundImage: `url(${BackgroundVector1})` }}
                >
                  <Row style={{ height: "100%" }}>
                    <Col span={12}>
                      <Space direction="vertical" size={0}>
                        <Icon
                          component={MoneyIconSVG}
                          style={{
                            color: "#044E54",
                            fontSize: 24,
                            backgroundColor: "#E0FCFF",
                            padding: 6,
                            borderRadius: 4,
                          }}
                        />
                        <Text
                          type="secondary"
                          className="transactions-report__card-title"
                        >
                          {intl.formatMessage({
                            id: "2573",
                            defaultMessage: "Total transactions",
                          })}
                        </Text>
                        <Text className="transactions-report__card-value">
                          {intl.formatNumber(
                            Number(data?.Meta?.TotalRecords || 0)
                          )}
                        </Text>
                        {currenciesData && (
                          <>
                            <hr />
                            <Text
                              type="secondary"
                              className="transactions-report__card-title"
                            >
                              {intl.formatMessage({
                                id: "2776",
                                defaultMessage: "Total credits",
                              })}
                            </Text>
                            <Text
                              type="success"
                              className="transactions-report__card-value"
                            >
                              <FormattedNumber
                                currency={currenciesData?.Code}
                                style="currency"
                                currencyDisplay="code"
                                maximumFractionDigits={2}
                                value={Number(currenciesData?.TotalCredit || 0)}
                              />
                            </Text>
                          </>
                        )}
                      </Space>
                    </Col>
                    <Col span={12} className="transactions-report__card-bottom">
                      <Space direction="vertical" size={0}>
                        <Icon
                          component={InvestmentIconSVG}
                          style={{
                            color: "#4C63B6",
                            fontSize: 24,
                            backgroundColor: "#E0E8F9",
                            padding: 6,
                            borderRadius: 4,
                          }}
                        />
                        <Text
                          type="secondary"
                          className="transactions-report__card-title"
                        >
                          {intl.formatMessage({
                            id: "2776",
                            defaultMessage: "Transactions volume",
                          })}
                        </Text>
                        <Text className="transactions-report__card-value">
                          <FormattedNumber
                            currency={TransVolume?.BaseCurrency}
                            style="currency"
                            currencyDisplay="code"
                            maximumFractionDigits={2}
                            value={Number(
                              TransVolume?.TotalTransationsVolume || 0
                            )}
                          />
                        </Text>
                        {currenciesData && (
                          <>
                            <hr />
                            <Text
                              type="secondary"
                              className="transactions-report__card-title"
                            >
                              {intl.formatMessage({
                                id: "2776",
                                defaultMessage: "Total debits",
                              })}
                            </Text>
                            <Text
                              type="danger"
                              className="transactions-report__card-value"
                            >
                              <FormattedNumber
                                currency={currenciesData.Code}
                                style="currency"
                                currencyDisplay="code"
                                maximumFractionDigits={2}
                                value={Number(currenciesData?.TotalDebit || 0)}
                              />
                            </Text>
                          </>
                        )}
                      </Space>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={10}
                  className="transactions-report__card link"
                  style={{ backgroundImage: `url(${BackgroundVector2})` }}
                >
                  <Row>
                    <Col span={18}>
                      <Space direction="vertical">
                        <Text className="transactions-report__title">
                          {intl.formatMessage({
                            id: "2777",
                            defaultMessage: "Comprehensive report",
                          })}
                        </Text>
                        <Text type="secondary">
                          A comprehensive view of all transactions grouped
                          together to show you the ones bringing in more revenue
                        </Text>
                        <Link to="/transactions-report/summary">
                          View report&nbsp;&nbsp;
                          <Icon component={ContinueIconSvg} />
                        </Link>
                      </Space>
                    </Col>
                    <Col span={6}>
                      <div style={{ marginLeft: -32, marginTop: -16 }}>
                        <img
                          src={SummaryReportImage}
                          alt="Comprehensive report"
                          style={{ height: 196, width: 189 }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={25}>
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Title level={4}>
                        {intl.formatMessage({
                          id: "2778",
                          defaultMessage: "Transactions list",
                        })}
                      </Title>
                    </Col>

                    <Col span={24}>
                      <Row gutter={[24, 0]} wrap={false}>
                        <Col span={4}>
                          <Input
                            allowClear
                            value={PID}
                            onChange={(e) => setPID(e.target.value)}
                            placeholder={intl.formatMessage({
                              id: "2779",
                              defaultMessage: "Search by username",
                            })}
                          />
                        </Col>

                        <Col span={4}>
                          <Input
                            allowClear
                            value={walletNumber}
                            onChange={(e) => setWalletNumber(e.target.value)}
                            placeholder={intl.formatMessage({
                              id: "2780",
                              defaultMessage: "Search by wallet number",
                            })}
                          />
                        </Col>

                        <Col>
                          <RangePicker
                            placeholder={["From", "To"]}
                            value={[dateRange[0], dateRange[1]]}
                            onChange={(values, rangeString) => {
                              // @ts-expect-error
                              setDateRange([values[0], values[1]]);
                              setFilters((prev) => ({
                                ...prev,
                                DateFrom: rangeString[0],
                                DateTo: rangeString[1],
                              }));
                            }}
                          />
                        </Col>

                        <Col>
                          <Badge
                            color="blue"
                            count={filters.TransactionType?.length}
                          >
                            <Button
                              onClick={() => setFiltersModalVisible(true)}
                            >
                              {intl.formatMessage({
                                id: "dd.new",
                                defaultMessage: "Transaction type",
                              })}
                            </Button>
                          </Badge>
                        </Col>

                        <Col>
                          <Badge
                            color="blue"
                            count={filters.Membership?.length}
                          >
                            <Button
                              onClick={() =>
                                setPartnersFiltersModalVisible(true)
                              }
                            >
                              {intl.formatMessage({
                                id: "dd.new",
                                defaultMessage: "Partners",
                              })}
                            </Button>
                          </Badge>
                        </Col>

                        <div style={{ flexGrow: 1 }} />

                        <Col>
                          <Space>
                            <Button
                              disabled={isFetching}
                              icon={<FileExcelOutlined />}
                              onClick={() => setOpenExportToXLSX(true)}
                            >
                              {intl.formatMessage({
                                id: "1465",
                                defaultMessage: "Export to XLSX",
                              })}
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <TransactionsTable
                    loading={isFetching}
                    filters={filters}
                    transactions={data?.Data || []}
                    pagination={{
                      onChange: (page, size) =>
                        setFilters((prev) => ({
                          ...prev,
                          PageNumber: page.toString(),
                          RecordPerPage: size.toString(),
                        })),
                      pageSize: Number(data?.Meta?.RecordsperPage || 10),
                      total: Number(data?.Meta?.TotalRecords || 0),
                    }}
                  />
                </Col>

                <TransactionsFiltersModal
                  visible={isFiltersModalVisible}
                  onCancel={() => setFiltersModalVisible(false)}
                  onSaveFilters={(v) =>
                    setFilters((prev) => ({
                      ...prev,
                      ...v,
                    }))
                  }
                />
                <TransactionsPartnersFiltersModal
                  visible={isPartnersFiltersModalVisible}
                  onCancel={() => setPartnersFiltersModalVisible(false)}
                  onSaveFilters={(v) =>
                    setFilters((prev) => ({
                      ...prev,
                      ...v,
                    }))
                  }
                  countryCode={
                    globalBuiltinConfigData?.data?.BaseCountry?.BaseCountryCode
                  }
                />

                <DownloadConfigModal
                  initialValues={filters}
                  visible={downloadConfigVisible}
                  onClose={() => setDownloadConfigVisible(false)}
                />

                <ExportToXLSX
                  initialValues={data}
                  visible={openExportToXLSX}
                  selectOptionForm={selectOptionForm}
                  setSelectOptionForm={setSelectOptionForm}
                  onClose={() => setOpenExportToXLSX(false)}
                  defaultFilters={filters}
                />
              </Row>
            </Col>
          </Row>
        </Spin>
      </PageAccessControl>
    </PageHOCComponent>
  );
};

export default TransactionReport;
